// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Grid } from 'swiper';

import { useSwiperInit } from '@src/libs/hooks/useSwiperInit';
import BtnArrow from '@src/components/BtnArrow';
import EventCard from '@src/components/EventCard';

import css from './style.module.scss';

const HomepageEvents = ({
	items,
	forceVisibility,
	title,
	description,
	sorted,
	adaptive,
	sliderTrackMix,
	state,
}) => {
	const { handleSwiper, slideNext, slidePrev, isStart, isEnd } =
		useSwiperInit();

	const sortedItems = React.useMemo(() => {
		if (!sorted) return items;

		const firstHalf = [];
		const secondHalf = [];

		items.forEach((item, i) => {
			(i % 2 === 0 ? firstHalf : secondHalf).push(item);
		});

		return [...firstHalf, ...secondHalf];
	}, [items, sorted]);

	const lineQtty = Math.ceil(items.length / 2);
	const initialWidth = lineQtty * 156;
	const initStyles = `.${css.wrapper} :not(.swiper-initialized) .swiper-wrapper { width: ${initialWidth}px; flex-wrap: wrap; }`;

	return (
		<div
			className={cn(css.block, {
				[css[`block_adapt_${adaptive}`]]: !!adaptive,
				[css[`block_state_${state}`]]: !!state,
			})}
		>
			{title && <h2 className={css.heading}>{title}</h2>}
			{description && <p className={css.text}>{description}</p>}
			{items.length > 0 && (
				<div className={css.wrapper}>
					<style>{initStyles}</style>
					<Swiper
						modules={[Grid]}
						grid={{ rows: 2, fill: 'row' }}
						slidesPerView="auto"
						onSwiper={handleSwiper}
						simulateTouch={false}
						className={cn(css.track, sliderTrackMix)}
					>
						{sortedItems.map((item) => {
							return (
								<SwiperSlide
									key={`${item.src}_${item.href}`}
									className={css.slide}
								>
									<EventCard
										title={item.title}
										src={item.src}
										src2x={item.src2x}
										forceVisibility={forceVisibility}
										href={item.href}
									/>
								</SwiperSlide>
							);
						})}
						<div slot="wrapper-end" className={css.blank} />
					</Swiper>
					<div className={css.arrows}>
						<span
							tabIndex={0}
							role="button"
							className={cn(css.arrow, css.arrow_left)}
							onClick={slidePrev}
						>
							<BtnArrow
								direction="left"
								type="small"
								disabled={isStart}
								className={css.iconBtn}
							/>
						</span>
						<span
							tabIndex={0}
							role="button"
							className={cn(css.arrow, css.arrow_right)}
							onClick={slideNext}
						>
							<BtnArrow
								direction="right"
								type="small"
								disabled={isEnd}
								className={css.iconBtn}
							/>
						</span>
					</div>
				</div>
			)}
		</div>
	);
};

HomepageEvents.propTypes = {
	/**
	 * Заголовок блока.
	 */
	title: PropTypes.string,
	/**
	 * Описание блока.
	 */
	description: PropTypes.string,
	/**
	 * Массив объектов. Описывает элементы навигации.
	 */
	items: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			src: PropTypes.string,
			href: PropTypes.string,
		}),
	),
	/**
	 * Флаг форсирующий ленивую загрузку изображения, независимо от вьюпорта
	 */
	forceVisibility: PropTypes.bool,

	sorted: PropTypes.bool,

	adaptive: PropTypes.oneOf(['default', 'homepage']),

	sliderTrackMix: PropTypes.string,

	state: PropTypes.oneOf(['homepage']),
};

HomepageEvents.defaultProps = {
	title: undefined,
	description: undefined,
	items: [],
	forceVisibility: false,
	sorted: true,
	adaptive: 'homepage',
	sliderTrackMix: undefined,
	state: undefined,
};

export default HomepageEvents;
