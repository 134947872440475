import React from 'react';
import IconSearch from '@src/components/Icon/set/IconSearch';
import Btn from '../component';

const BlackIconBtn = (props) => {
	return (
		<Btn
			color="white"
			bg="black"
			iconSize="small"
			LeftIcon={IconSearch}
			{...props}
		/>
	);
};

export default BlackIconBtn;
