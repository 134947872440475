import { createSelector } from 'reselect';
import { getStrapiSFUEntires } from './getStrapiSFUEntries';

export const getStrapiSFUEntiresMapped = createSelector(
	getStrapiSFUEntires,
	(sfu) => {
		return sfu.map((i) => {
			const params = i.path_params.split('&');
			params.sort();

			return {
				params: params
					.map((param) => {
						const [prop, p] = param.split('=');
						const r = p.split(',');
						r.sort();
						return [prop, r.join(',')].join('=');
					})
					.join('&'),
				dir: i.slug,
			};
		});
	},
);
