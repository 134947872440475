import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import BlackIconBtn from '@src/components/Btn/set/BlackIconBtn';

import css from './style.module.scss';

const SearchForm = (props) => {
	const {
		Search,
		EventTypeFilter,
		GuestQttyFilter,
		CalendarFilter,
		submitLabel = t('Применить'),
		SubmitButton,
		title,
		isHomePage,
		onSubmit,
		headerNode,
	} = props;

	const result = (
		<>
			{Search && (
				<div className={css.item}>
					<Search />
				</div>
			)}
			{EventTypeFilter && (
				<div className={css.item}>
					<EventTypeFilter />
				</div>
			)}
			{GuestQttyFilter && (
				<div className={css.item}>
					<GuestQttyFilter />
				</div>
			)}
			{CalendarFilter && (
				<div className={css.item}>
					<CalendarFilter />
				</div>
			)}
			{SubmitButton && (
				<div className={css.submit}>
					<SubmitButton
						isSubmit
						full
						label={submitLabel}
						onClick={onSubmit}
						mix={cn(css.submitBtn, {
							[css.submitBtn_modal]: !isHomePage,
						})}
					/>
				</div>
			)}
		</>
	);

	if (isHomePage) {
		return (
			<div className={cn(css.homeWrap, css.homeWrap_adapt_home)}>
				{headerNode && (
					<div className={css.headerWrap}>{headerNode}</div>
				)}
				<div className={cn(css.formHolder, css.formHolder_adapt)}>
					{title && <div className={css.title}>{title}</div>}
					<div>{result}</div>
				</div>
			</div>
		);
	}

	return <div className={css.container}>{result}</div>;
};

SearchForm.propTypes = {
	headerNode: PropTypes.element,
	Search: PropTypes.elementType,
	EventTypeFilter: PropTypes.elementType,
	GuestQttyFilter: PropTypes.elementType,
	CalendarFilter: PropTypes.elementType,
	submitLabel: PropTypes.string,
	SubmitButton: PropTypes.elementType,
	title: PropTypes.string,
	onSubmit: PropTypes.func,
	isHomePage: PropTypes.bool,
};

SearchForm.defaultProps = {
	headerNode: undefined,
	Search: undefined,
	EventTypeFilter: undefined,
	GuestQttyFilter: undefined,
	CalendarFilter: undefined,
	submitLabel: undefined,
	SubmitButton: BlackIconBtn,
	title: undefined,
	onSubmit: undefined,
	isHomePage: false,
};

export default SearchForm;
