import { useCallback, useState } from 'react';
import { useUpdateEffect } from 'react-use';

const defaultSuggestionProcessor = (data) => {
	const { suggestions = [], query } = data;
	if (!query) return suggestions;
	return suggestions.filter((s) => {
		return s.label?.toLowerCase().includes(query.toLowerCase());
	});
};

const useSuggestions = ({
	initialQuery,
	setValue,
	suggestions,
	processor = defaultSuggestionProcessor,
}) => {
	const [query, setQuery] = useState(initialQuery);
	const [processedSuggestions, setSuggestions] = useState(
		processor({ suggestions, query }),
	);
	const [isPopoverOpen, setPopoverOpen] = useState(false);

	useUpdateEffect(() => {
		setSuggestions(processor({ query, suggestions }));
	}, [query, suggestions, processor]);

	const handleChange = useCallback(
		(data) => {
			setQuery(data);
		},
		[setQuery],
	);

	const handleFocusChange = useCallback(
		(is) => {
			if (!is) {
				setPopoverOpen(false);
			} else {
				setPopoverOpen(true);
			}
		},
		[setPopoverOpen],
	);

	const handleResetQuery = useCallback(() => {
		setQuery('');
		setValue('');
		setPopoverOpen(true);
	}, [setQuery, setValue]);

	const handleSelectSuggestion = useCallback(
		(suggestion) => {
			setValue(suggestion.value);
			setQuery(suggestion.label);
			setPopoverOpen(false);
		},
		[setValue, setQuery, setPopoverOpen],
	);

	return {
		query,
		isPopoverOpen,
		processedSuggestions,
		setQuery,
		handleChange,
		handleFocusChange,
		handleResetQuery,
		handleSelectSuggestion,
		setPopoverOpen,
	};
};

export default useSuggestions;
