import { createSelector } from 'reselect';
import getPlaceDetails from '@src/containers/PlaceProfile/selectors/getPlaceDetails';
import { getCity } from '@src/containers/Session/selectors/getCity';
import { getSFUValues } from './getSFUValues';

const getQuery = (store) => store.Session.query;
const getParams = (store) => store.Session.params;

const getSearchFormValues = createSelector(
	getQuery,
	getParams,
	getCity,
	getPlaceDetails,
	getSFUValues,
	(query, params, city = {}, placeDetails, sfuValues) => {
		const { town, place_id } = params;
		const place_id_tmp = null && place_id; // Switch on/off place vals

		const target = place_id_tmp
			? {
					name: placeDetails.name,
					type: 'place',
					details: { id: placeDetails.id, city: { domain: town } },
			  }
			: { name: city.name, details: city, type: 'city' };

		const visibleFields = [];
		const { spec, capacity, date } = { ...query, ...sfuValues };

		if (!spec) {
			visibleFields.push('spec');
		} else if (!capacity) {
			visibleFields.push('capacity');
		}

		return {
			spec,
			capacity,
			date,
			city: target,
			visibleFields,
		};
	},
);

export default getSearchFormValues;
