import { getSFUValues } from '@src/containers/Filters/selectors/getSFUValues';
import { ROUTE_PATHS } from '@src/containers/Session/constants';
import { useActions } from '@src/containers/Session/hooks';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ANALYTICS_GOAL, useReachGoal } from './useReachGoal';

const useSearchFormSubmit = ({ targetUrl, initialValues } = {}) => {
	const router = useRouter();
	const sfu = useSelector(getSFUValues);
	const { setCityFromUrl } = useActions();
	const { reachGoal } = useReachGoal();

	const handleSubmit = useCallback(
		(values) => {
			const newQuery = { ...router.query, ...sfu };
			let cityData;

			delete newQuery.page;
			delete newQuery.seo_slug;

			const iteratedValues = initialValues || values;

			Object.keys(iteratedValues).forEach((key) => {
				if (['city', 'visibleFields'].includes(key)) {
					return;
				}
				if (!values[key]) {
					delete newQuery[key];
				} else {
					newQuery[key] = values[key];
				}
			});

			if (values.city?.type === 'city') {
				cityData = values.city.details;
				newQuery.town = cityData.domain;
			}

			if (values.city?.type === 'place') {
				cityData = values.city.details.city;
				newQuery.town = cityData.domain;
				newQuery.place_id = values.city.details.id;
			}

			let targetPathname =
				values.city?.type === 'place'
					? ROUTE_PATHS.PLACE
					: ROUTE_PATHS.LISTING;

			if (targetUrl) {
				targetPathname = targetUrl;
			}

			if (!router.route.includes('[space_id]')) {
				delete newQuery.space_id;
			}

			delete newQuery.page;

			const shallow =
				router.pathname === targetPathname &&
				values.city?.type === 'city';

			const goalParams = {};

			if (values.spec !== undefined) goalParams.spec = values.spec;

			if (values.capacity !== undefined)
				goalParams.capacity = values.capacity;

			reachGoal(ANALYTICS_GOAL.send_search_form, goalParams);
			router.push({ pathname: targetPathname, query: newQuery }, null, {
				shallow,
			});

			setCityFromUrl({ cityData });
		},
		[router, setCityFromUrl, targetUrl, initialValues, sfu, reachGoal],
	);

	return handleSubmit;
};

export default useSearchFormSubmit;
