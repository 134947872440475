import qs from 'qs';
import { createSelector } from 'reselect';
import getRouterCtx from '@src/containers/Session/selectors/getRouterCtx';
import { ROUTE_PATHS } from '@src/containers/Session/constants';
import { getCity } from '@src/containers/Session/selectors/getCity';
import { getStrapiSFUEntiresMapped as getSFUEntires } from '@src/containers/Filters/selectors/getStrapiSFUEntriesMapped';

import { SUGGESTION_PNG_IMGS } from '../images';
import { getSFUValues } from './getSFUValues';

const serverSuggestionItems = (store) =>
	store.Filters.loadFilters?.data?.entity?.spec?.value || [];

export const getSuggestions = createSelector(
	serverSuggestionItems,
	getRouterCtx,
	getCity,
	getSFUEntires,
	getSFUValues,
	(items, ctx, city, sfu, sfuValues) => {
		const { query } = ctx;
		const { domain } = city;

		return items.map((item) => {
			const newQuery = { ...query, ...sfuValues, spec: item.key };

			delete newQuery.page;

			const compareString = qs.stringify(newQuery, {
				arrayFormat: 'comma',
				encode: false,
			});

			const targetSFU = sfu.find((i) => i.params === compareString);

			let href = `${ROUTE_PATHS.LISTING.replace('[town]', domain)}`;

			if (targetSFU) {
				href = `${href}${targetSFU.dir}`;
			} else {
				href = `${href}?${compareString}`;
			}

			const src = SUGGESTION_PNG_IMGS.find(
				(suggestionItem) => suggestionItem.key === +item.key,
			)?.src;

			return {
				title: item.name,
				src,
				src2x: src?.replace('.png', '@2x.png'),
				href,
				isShallow: !targetSFU,
			};
		});
	},
);
