import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '@src/components/Input';
import Popover from '@src/components/Popover';
import Dropdown from '@src/components/Dropdown';
import { ArrowSmallLIcon } from '@src/components/Icon';

const SuggestedSearch = (props) => {
	const {
		value,
		placeholder,
		name,
		onInput,
		onFocus,
		onBlur,
		onChange,
		isPopover,
		popoverClass,
		suggestions,
		onSuggestionClick,
		onInputClean,
		MenuItem,
		textInputProps,
		errorText,
		mix,
		selectedValue,
		isNoBorder,
	} = props;

	const dropdownLayout =
		popoverClass === 'tag-cloud-x-4-6-x' ? 'cloud' : popoverClass;

	return (
		<div style={{ position: 'relative' }}>
			<Popover isOpen={isPopover} isNoBorder={isNoBorder}>
				<TextInput
					placeholder={placeholder}
					appearance="select"
					name={name}
					value={value}
					onInput={onInput}
					onFocus={onFocus}
					onBlur={onBlur}
					onChange={onChange}
					onInputCleaned={onInputClean}
					Append={ArrowSmallLIcon}
					errorText={errorText}
					mods={mix}
					{...textInputProps}
				/>
				<Dropdown
					items={suggestions}
					isNothing={suggestions.length === 0}
					onSelectItem={onSuggestionClick}
					MenuItem={MenuItem}
					layout={dropdownLayout}
					selectedValue={selectedValue}
				/>
			</Popover>
		</div>
	);
};

SuggestedSearch.propTypes = {
	value: PropTypes.string,
	placeholder: PropTypes.string,
	name: PropTypes.string,
	onInput: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	isPopover: PropTypes.bool,
	popoverClass: PropTypes.string,
	suggestions: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
		}),
	),
	onSuggestionClick: PropTypes.func,
	MenuItem: PropTypes.elementType,
	onInputClean: PropTypes.func,
	textInputProps: PropTypes.shape(TextInput.type.propTypes),
	errorText: PropTypes.string,
	mix: PropTypes.string,
	selectedValue: PropTypes.string,
	isNoBorder: PropTypes.bool,
	CleanInputButton: PropTypes.elementType,
};

SuggestedSearch.defaultProps = {
	mix: undefined,
	value: undefined,
	placeholder: undefined,
	name: undefined,
	onInput: undefined,
	onFocus: undefined,
	onBlur: undefined,
	onChange: undefined,
	isPopover: false,
	popoverClass: undefined,
	suggestions: [],
	onSuggestionClick: undefined,
	MenuItem: undefined,
	onInputClean: undefined,
	textInputProps: undefined,
	errorText: undefined,
	selectedValue: undefined,
	isNoBorder: true,
	CleanInputButton: undefined,
};

export default SuggestedSearch;
