import React from 'react';
import PropTypes from 'prop-types';
import SearchFormCHomepage from '@src/components/SearchForm/connectors/homepage';
import SeoBlockCDefault from '@src/components/SeoBlock/connectors/default';
import FooterCDefault from '@src/components/Footer/connectors/default';

import LayoutBase from '..';

const LayoutBaseCHome = (props) => {
	const { children, modals } = props;

	const Footer = React.useCallback((p) => {
		return <FooterCDefault noMap {...p} />;
	}, []);

	return (
		<>
			<LayoutBase
				modals={modals}
				HomepageSearchForm={SearchFormCHomepage}
				Footer={Footer}
				SeoBlock={SeoBlockCDefault}
			>
				{children}
			</LayoutBase>
		</>
	);
};

LayoutBaseCHome.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	modals: PropTypes.node,
};

LayoutBaseCHome.defaultProps = {
	children: undefined,
	modals: undefined,
};

export default LayoutBaseCHome;
