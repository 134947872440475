import React from 'react';

import HomepageAbout from './component';

const Template = (args) => <HomepageAbout {...args} />;
export const Primary = Template.bind({});
Primary.args = {
	title: 'Преимущества Spotlyst',
	items: [
		{
			id: 1,
			caption: 'Расширенный поиск',
			content:
				'Ищете ли вы подходящую цену или важные для вас особенности — упростите поиск с помощью наших многочисленных фильтров.',
			img: {
				src: '/img/illustrations/extended-search.svg',
				width: 108,
				height: 99,
			},
		},
		{
			id: 2,
			caption: 'Точная информация о занятости',
			content:
				'Мы знаем доступно ли выбранное место в интересующий вас день. Если дата оказалась занята — не беда, мы предложим альтернативный день или похожие площадки.',
			img: {
				src: '/img/illustrations/fast-response.svg',
				width: 104,
				height: 95,
			},
		},
		{
			id: 3,
			caption: 'Быстрый отклик',
			content:
				'Нашли идеальное место? Свяжитесь напрямую с владельцами и менеджерами заведений, чтобы организовать бронирование.',
			img: {
				src: '/img/illustrations/occupation-info.svg',
				width: 108,
				height: 99,
			},
		},
	],
};

export const LongPlates = Template.bind({});
LongPlates.args = {
	...Primary.args,
	state: 'long-plates',
};

export const Cols = Template.bind({});
Cols.args = {
	...Primary.args,
	state: 'cols',
};

export default {
	title: 'Components/HomepageAbout',
	component: HomepageAbout,
};
