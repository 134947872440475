import { useActions } from '@src/containers/Session/hooks';
import { getFormerSuggestionByKey } from '@src/containers/Session/selectors/getFormerSuggestions';
import { useSelector } from 'react-redux';

const useFormerSuggestions = (dbKey) => {
	const formerSuggestions = useSelector(getFormerSuggestionByKey(dbKey));
	const { saveSuggestion } = useActions();

	return {
		formerSuggestions,
		saveSuggestion: saveSuggestion(dbKey),
	};
};

export default useFormerSuggestions;
