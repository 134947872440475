// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useSwiperInit } from '@src/libs/hooks/useSwiperInit';
import Space from '@src/components/Space';
import BtnArrow from '@src/components/BtnArrow';

import css from './style.module.scss';

const HomepagePopular = (props) => {
	const {
		places,
		onClickLike,
		title,
		description,
		adaptive,
		sliderTrackMix,
	} = props;
	const { handleSwiper, isStart, isEnd, slideNext, slidePrev } =
		useSwiperInit();

	const fallback = React.useMemo(() => {
		return {
			width: 246,
			height: 164,
		};
	}, []);

	return (
		<div
			className={cn(css.block, {
				[css[`block_adapt_${adaptive}`]]: !!adaptive,
			})}
		>
			{title && <div className={css.title}>{title}</div>}
			{description && (
				<div className={css.description}>{description}</div>
			)}
			<div className={css.wrapper}>
				<Swiper
					slidesPerView="auto"
					simulateTouch={false}
					onSwiper={handleSwiper}
					className={cn(css.track, sliderTrackMix)}
				>
					{places.map((space) => (
						<SwiperSlide
							key={`${space.name}_${space.id}`}
							className={css.slide}
						>
							<Space
								state="medium"
								onClickHeart={onClickLike}
								mediaQueries={null}
								{...space}
								preview={null}
								imgWidth={fallback.width}
								imgHeight={fallback.height}
								fallback={fallback}
							/>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
			<div className={css.arrows}>
				<span
					tabIndex={0}
					role="button"
					className={cn(css.arrow, css.arrow_left)}
					onClick={slidePrev}
				>
					<BtnArrow
						direction="left"
						type="small"
						disabled={isStart}
						className={css.iconBtn}
					/>
				</span>
				<span
					tabIndex={0}
					role="button"
					className={cn(css.arrow, css.arrow_right)}
					onClick={slideNext}
				>
					<BtnArrow
						direction="right"
						type="small"
						disabled={isEnd}
						className={css.iconBtn}
					/>
				</span>
			</div>
		</div>
	);
};

HomepagePopular.propTypes = {
	/**
	 * Заголовок блока.
	 */
	title: PropTypes.string,
	/**
	 * Описание блока.
	 */
	description: PropTypes.string,
	/**
	 * Массив объектов. Описывает свойства карточек.
	 */
	places: PropTypes.arrayOf(PropTypes.shape({ ...Space.type.propTypes })),
	/**
	 * Обработчик события клика по кнопке добавления в избранное.
	 */
	onClickLike: PropTypes.func,

	adaptive: PropTypes.oneOf(['default']),
	sliderTrackMix: PropTypes.string,
};

HomepagePopular.defaultProps = {
	title: undefined,
	description: undefined,
	places: [],
	onClickLike: undefined,
	adaptive: 'default',
	sliderTrackMix: undefined,
};

export default HomepagePopular;
