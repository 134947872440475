import React from 'react';
import { useField } from 'formik';
import useTownPlaceSearch from '@src/libs/hooks/useTownPlaceSearch';

import useFormerSuggestions from '@src/libs/hooks/useFormerSuggestions';
import VenueSearch from '..';

const VenueSearchCDefault = () => {
	const [isFocused, setFocused] = React.useState(false);

	// eslint-disable-next-line no-unused-vars
	const [field, _meta, { setValue }] = useField('city');

	const initialQuery = field.value?.name || '';

	const { query, setQuery, suggestions, loading } = useTownPlaceSearch({
		initialQuery,
	});

	const { formerSuggestions, saveSuggestion } =
		useFormerSuggestions('venueSearch');

	const handleResetQuery = React.useCallback(() => {
		setQuery(initialQuery);
	}, [setQuery, initialQuery]);

	const handleFocusSearchField = React.useCallback(
		(is) => {
			setFocused(Boolean(is));
			const { target, type } = is;

			if (type === 'focus') {
				target.setSelectionRange(0, 9999);
			} else {
				setQuery(initialQuery);
				setFocused(false);
			}
		},
		[initialQuery, setQuery],
	);

	const handleSelectSuggestion = React.useCallback(
		(suggestion) => {
			const { suggestionType } = suggestion;

			setValue({
				name: suggestion.details.name,
				type: suggestionType,
				details: suggestion.details,
			});
			setQuery(suggestion.details.name);
			saveSuggestion(suggestion);
			setFocused(false);
		},
		[setValue, saveSuggestion, setQuery],
	);

	return (
		<VenueSearch
			searchQuery={query}
			searchSuggestions={suggestions}
			onChangeQuery={setQuery}
			isDropdownVisible={
				isFocused &&
				!loading &&
				(query.length > 1 || formerSuggestions.length > 0)
			}
			onFocusSearchField={handleFocusSearchField}
			onResetQuery={handleResetQuery}
			onSelectSuggestion={handleSelectSuggestion}
			isLoading={isFocused && loading && query.length > 1}
			formerTip={
				formerSuggestions.length > 0 ? t('Ранее вы искали') : null
			}
			formerSuggestions={formerSuggestions}
			isNothing={suggestions.length === 0 && query.length > 1}
		/>
	);
};

export default VenueSearchCDefault;
