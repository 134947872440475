import Tag from '@src/components/Tag';
import { getCapacityItems } from '@src/containers/Filters/selectors/getCapacity';
import useSuggestions from '@src/libs/hooks/useSuggestions';
import { useField } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';

import SuggestedSearch from '..';
import { useInputAsSelect } from '../hooks';

const SuggestedSearchCGuests = () => {
	const capacityItems = useSelector(getCapacityItems);
	const [field, , { setValue }] = useField('capacity');
	const initialQuery =
		capacityItems.find((ci) => field.value === ci.value)?.label || '';

	const {
		query,
		isPopoverOpen,
		handleFocusChange,
		handleSelectSuggestion,
		handleResetQuery,
	} = useSuggestions({ initialQuery, setValue });

	const { textInputProps } = useInputAsSelect({
		isOpen: isPopoverOpen,
		handleFocusChange,
	});

	return (
		<SuggestedSearch
			placeholder={t('Сколько гостей?')}
			name="capacity"
			value={query ? `${query} ${t('гостей')}` : query}
			isPopover={isPopoverOpen}
			suggestions={capacityItems}
			onBlur={handleFocusChange}
			onSuggestionClick={handleSelectSuggestion}
			onInputClean={handleResetQuery}
			popoverClass="tag-cloud-x-4-6-x"
			MenuItem={Tag}
			selectedValue={field.value}
			textInputProps={textInputProps}
		/>
	);
};

export default SuggestedSearchCGuests;
