import React from 'react';
import PropTypes from 'prop-types';
import Input from '@src/components/Input';
import Popover from '@src/components/Popover';
import Dropdown from '@src/components/Dropdown';
import { LoadingIcon } from '@src/components/Icon';

const VenueSearch = (props) => {
	const {
		searchQuery,
		searchSuggestions,
		isDropdownVisible,
		isLoading,
		onChangeQuery,
		onFocusSearchField,
		onSelectSuggestion,
		onResetQuery,
		formerTip,
		formerSuggestions,
		isNothing,
	} = props;

	return (
		<Popover isOpen={isDropdownVisible} isNoBorder>
			<Input
				name="city"
				theme="shadow"
				type="text"
				appearance="select"
				placeholder={t('Город или название площадки')}
				onInput={onChangeQuery}
				onFocus={onFocusSearchField}
				onInputCleaned={onResetQuery}
				onBlur={onFocusSearchField}
				value={searchQuery}
				Append={isLoading ? LoadingIcon : null}
				loading={isLoading}
			/>
			{isLoading ? (
				<span>{t('Загрузка...')}</span>
			) : (
				<>
					<Dropdown
						isLoading={isLoading}
						layout="extended"
						items={searchSuggestions}
						isNothing={isNothing}
						onSelectItem={onSelectSuggestion}
					/>
					<Dropdown
						tip={formerTip}
						layout="extended"
						items={formerSuggestions}
						onSelectItem={onSelectSuggestion}
					/>
				</>
			)}
		</Popover>
	);
};

VenueSearch.propTypes = {
	/**
	 * Массив объектов, описывающих поисковые предложения.
	 */
	searchSuggestions: Dropdown.type.propTypes.items,
	/**
	 * Текущее значение поискового запроса для поля поиска.
	 */
	searchQuery: PropTypes.string,
	/**
	 * Флаг видимости выпадающего меню.
	 */
	isDropdownVisible: PropTypes.bool,
	/**
	 * Флаг процесса загрузки поисковых предложений.
	 */
	isLoading: PropTypes.bool,
	/**
	 * Обработчик события изменения запроса
	 */
	onChangeQuery: PropTypes.func,
	/**
	 * Обработчик события фокусирования поискового поля.
	 */
	onFocusSearchField: PropTypes.func,
	/**
	 * Обработчик события выбора (клика) поискового предложения.
	 */
	onSelectSuggestion: PropTypes.func,
	/**
	 * Обработчик события очистки поисковой строки.
	 */
	onResetQuery: PropTypes.func,
	/**
	 * Подсказка для списка предыдущих поисковых предложений
	 */
	formerTip: PropTypes.string,
	/**
	 * Предыдущие предложения поиска
	 */
	formerSuggestions: Dropdown.type.propTypes.items,
	/**
	 * Флаг, переключает состояние "Ничего не найдено"
	 */
	isNothing: PropTypes.bool,
};

VenueSearch.defaultProps = {
	searchSuggestions: [],
	searchQuery: undefined,
	isDropdownVisible: false,
	isLoading: false,
	onChangeQuery: undefined,
	onFocusSearchField: undefined,
	onSelectSuggestion: undefined,
	onResetQuery: undefined,
	formerTip: undefined,
	formerSuggestions: [],
	isNothing: false,
};

export default VenueSearch;
