import { mapServerImg } from '../mapServerImg';

const mapRatingAndImgs = (mappedItem, { item, index }) => {
	const { media, rating, isNonFit } = item;
	const { tags } = mappedItem;

	if (rating) {
		tags.push({
			text: rating,
			name: 'rating',
			size: 'small',
			bgColor: 'gray',
			leftIcon: 'ratingStar',
		});
	}

	return {
		...mappedItem,
		imgs:
			media?.map((img) => ({
				...mapServerImg(img),
				forceVisibility: index < 9,
			})) || [],
		tags,
		infoTag: isNonFit ? t('Не подходит по параметрам') : undefined,
	};
};

export default mapRatingAndImgs;
