import React from 'react';
import Head from 'next/head';
import { useSelector } from 'react-redux';
import { getTitles } from '@src/containers/Session/selectors/getTitles';

const SeoHeadCHomepage = () => {
	const seo = useSelector(getTitles);
	const { title, description } = seo || {};

	if (!title) {
		return (
			<Head>
				<meta name="robots" content="noindex" />
			</Head>
		);
	}

	return (
		<Head>
			{title && <title>{title}</title>}
			{description && (
				<meta
					name="description"
					content={description}
					key="description"
				/>
			)}
		</Head>
	);
};

export default SeoHeadCHomepage;
