import React from 'react';
import { useSelector } from 'react-redux';
import useHandleClickLike from '@src/containers/Session/hooks/useHandleClickLike';
import getMappedSpaces from '@src/containers/SpacesListing/selectors/getMappedSpaces';
import getPopularParams from '@src/containers/Homepage/selectors/getPopularParams';

import HomepagePopular from '..';

const HomepagePopularCDefault = (props) => {
	const places = useSelector(getMappedSpaces);
	const handleClickLike = useHandleClickLike();
	const { title, description } = useSelector(getPopularParams);

	return (
		places.length > 0 && (
			<HomepagePopular
				{...props}
				places={places}
				onClickLike={handleClickLike}
				title={title}
				description={description}
			/>
		)
	);
};

export default HomepagePopularCDefault;
