import { mapServerImg } from '../mapServerImg';
import reduceMetroColors from '../reduceMetroColors';

const mapServerSpace =
	({ favIds, town, additional, first_id }) =>
	(i = {}, k) => {
		const metros = reduceMetroColors(i.metro).map((m) => ({
			...m,
			colors: Array.isArray(m.color) ? m.color : [m.color],
		}));

		const preview = i.media && i.media[0] && mapServerImg(i.media[0]);

		const tags = i.cardParam?.map((param) => {
			const text = param.text.replace('<sup>2</sup>', '²');
			if (param.isPrice) {
				return { text, bgColor: 'green', size: 'small' };
			}
			return {
				name: param.name,
				text,
				size: 'small',
			};
		});

		const additionalConverter = additional || ((it) => it);

		const result = additionalConverter(
			{
				id: i.id,
				linkTo: `/${i.cityDomain || town}/spaces/${i.id}`,
				name: i.name,
				category: i.venueName,
				description: i.description,
				tags,
				preview: preview
					? {
							...preview,
							width: 144,
							height: 144,
					  }
					: undefined,
				address: {
					metros,
					address: i.address,
				},
				shortAddress: i.address || 'Адрес. Нет данных от апи',
				isLiked: favIds.some((favId) => i.id === favId),
				imgs: [],
				coordinate: i.coordinate,
				appearance:
					!Number.isNaN(first_id) && first_id === i.id
						? 'firstId'
						: undefined,
			},
			{ item: i, index: k },
		);

		return result;
	};

export default mapServerSpace;
