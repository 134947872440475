import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Img from '@src/components/Img';

import css from './style.module.scss';

const HomepageAbout = (props) => {
	const { title, items, state, adaptive } = props;
	return (
		<div
			className={cn(css.block, {
				[css[`block_state_${state}`]]: !!state,
				[css[`block_adapt_${adaptive}`]]: !!adaptive,
			})}
		>
			{title && <div className={css.title}>{title}</div>}
			{items.length > 0 && (
				<div className={css.items}>
					{items.map((item) => {
						const { id, caption, img, content } = item;
						return (
							<div key={id} className={css.item}>
								{img && (
									<div className={css.imgHolder}>
										<Img className={css.img} {...img} />
									</div>
								)}
								<div className={css.contentHolder}>
									{caption && (
										<div className={css.caption}>
											{caption}
										</div>
									)}
									{content && (
										<div className={css.content}>
											{content}
										</div>
									)}
								</div>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

HomepageAbout.propTypes = {
	title: PropTypes.string,
	state: PropTypes.oneOf(['long-plates', 'cols']),
	adaptive: PropTypes.oneOf(['homepage']),
	items: PropTypes.arrayOf(
		PropTypes.shape({
			caption: PropTypes.string,
			img: PropTypes.shape(Img.type.propTypes),
			content: PropTypes.string,
		}),
	),
};

HomepageAbout.defaultProps = {
	title: undefined,
	state: undefined,
	adaptive: undefined,
	items: [],
};

export default HomepageAbout;
