import { createSelector } from 'reselect';
import { getSuggestions } from './getSuggestions';

const getHomepageSuggestions = createSelector(getSuggestions, (suggestions) => {
	return suggestions.map((suggestion) => {
		return {
			...suggestion,
			href: suggestion.href,
			isShallow: false,
		};
	});
});

export default getHomepageSuggestions;
