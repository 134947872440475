/* eslint-disable no-param-reassign */

const updateScreenPoints = (swiper, initIndex = 0) => {
	const { width } = swiper;
	const screens = [0];
	let nextScreenEdge = width;
	if (!swiper.isLocked) {
		let lastScreenPoint = 0;
		swiper.slidesGrid.forEach((point, i) => {
			const slideWidth = swiper.slidesSizesGrid[i];
			if (nextScreenEdge < point + slideWidth) {
				lastScreenPoint = point;
				nextScreenEdge = lastScreenPoint + width;
				screens.push(i);
			}
		});
	}
	swiper.screens = screens;
	swiper.activeScreen = swiper.activeScreen || initIndex;
};

const updateActiveScreen = (swiper) => {
	swiper.screens.some((t, i) => {
		if (t === swiper.activeIndex || i === swiper.screens.length - 1) {
			swiper.activeScreen = i;
			return true;
		}

		if (t > swiper.activeIndex) {
			swiper.activeScreen = i - 1;
			return true;
		}

		return false;
	});
};

function goToScreen(screenIndex) {
	const safeIndex = Math.min(
		Math.max(screenIndex, 0),
		this.screens.length - 1,
	);
	this.slideTo(this.screens[safeIndex]);
}

export const addSwiperScreensApi = (swiper, { initIndex = 0 } = {}) => {
	updateScreenPoints(swiper);

	swiper.on('update', (s) => {
		updateScreenPoints(s, initIndex);
	});

	swiper.on('resize', (s) => {
		updateScreenPoints(s, initIndex);
	});

	swiper.on('slideChange', (s) => {
		updateActiveScreen(s);
	});

	swiper.on('toEdge', (s) => {
		updateActiveScreen(s);
	});

	swiper.goToScreen = goToScreen.bind(swiper);
};
