import React from 'react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import VenueSearchCDefault from '@src/components/VenueSearch/connectors/default';
import getSearchFormValues from '@src/containers/Filters/selectors/getSearchFormValues';
import SuggestedSearchCEvents from '@src/components/SuggestedSearch/connectors/events';
import SuggestedSearchCGuests from '@src/components/SuggestedSearch/connectors/guests';
import useSearchFormSubmit from '@src/libs/hooks/useSearchFormSubmit';
import getHeader from '@src/containers/Homepage/selectors/getHeader';
import useLoadSuggestions from '@src/containers/Session/hooks/useLoadSuggestions';
import HeaderCHome from '@src/components/Header/connectors/home';

import SearchForm from '..';

const SearchFormCHomepage = () => {
	const handleSubmit = useSearchFormSubmit();
	const initialValues = useSelector(getSearchFormValues);
	const title = useSelector(getHeader);
	useLoadSuggestions('venueSearch');

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize
			onSubmit={handleSubmit}
		>
			{({ submitForm }) => {
				return (
					<SearchForm
						title={title}
						auxWrapper
						isHomePage
						containerClass=""
						headerNode={<HeaderCHome />}
						submitLabel={t('Найти')}
						Search={VenueSearchCDefault}
						EventTypeFilter={SuggestedSearchCEvents}
						GuestQttyFilter={SuggestedSearchCGuests}
						onSubmit={submitForm}
					/>
				);
			}}
		</Formik>
	);
};

export default SearchFormCHomepage;
