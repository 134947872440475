import React from 'react';
import { useDispatch } from 'react-redux';
import loadSuggestions from '../reducers/loadSuggestions';

const useLoadSuggestions = (dbKey) => {
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(loadSuggestions.action(dbKey));
	}, [dbKey, dispatch]);
};

export default useLoadSuggestions;
