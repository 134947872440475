import React from 'react';
import { useSelector } from 'react-redux';
import HomepageEventsCDefault from '@src/components/HomepageEvents/connectors/default';
import HomepagePopularCDefault from '@src/components/HomepagePopular/connectors/default';
import HomepageAboutCDefault from '@src/components/HomepageAbout/connectors/default';
import getMappedSpaces from '@src/containers/SpacesListing/selectors/getMappedSpaces';

import HomePage from '..';

const HomePageCDefault = () => {
	const popularPlaces = useSelector(getMappedSpaces);

	return (
		<HomePage
			HomepageEvents={HomepageEventsCDefault}
			HomepagePopular={
				popularPlaces.length > 0 ? HomepagePopularCDefault : null
			}
			HomepageAbout={HomepageAboutCDefault}
		/>
	);
};

export default HomePageCDefault;
