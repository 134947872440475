import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import css from './style.module.scss';

const HomePage = (props) => {
	const { HomepageEvents, HomepagePopular, HomepageAbout } = props;

	return (
		<>
			{HomepageEvents && (
				<div className={cn(css.box, css.box_events)}>
					<HomepageEvents
						sliderTrackMix={cn(
							css['slider-track-mix'],
							css['slider-track-mix_events'],
						)}
					/>
				</div>
			)}
			{HomepagePopular && (
				<div className={css.popular}>
					<HomepagePopular
						sliderTrackMix={cn(
							css['slider-track-mix'],
							css['slider-track-mix_popular'],
						)}
					/>
				</div>
			)}
			{HomepageAbout && (
				<div className={css.about}>
					<HomepageAbout adaptive="homepage" />
				</div>
			)}
		</>
	);
};

HomePage.propTypes = {
	/**
	 * React-компонент, меню навигации.
	 */
	HomepageEvents: PropTypes.elementType,
	/**
	 * React-компонент, популярные места.
	 */
	HomepagePopular: PropTypes.elementType,
	HomepageAbout: PropTypes.elementType,
};

HomePage.defaultProps = {
	HomepageEvents: undefined,
	HomepagePopular: undefined,
	HomepageAbout: undefined,
};

export default HomePage;
