import { createAsyncThunk } from '@reduxjs/toolkit';
import { ACTIONS_PREFIX } from '../constants';

const loadTownPlaceSuggestions = createAsyncThunk(
	`${ACTIONS_PREFIX}/loadTownPlaceSuggestions`,
	async ({ q }, { extra }) => {
		const { api } = extra;

		const reqCities = api.apiv2.get('cities', {
			params: { name: q, embed: ['country'], fields: ['name3'] },
		});

		const reqPlaces = api.apiv2.get('restaurants', {
			params: { name: q, type_id: 3 },
		});

		const [cities, places] = await Promise.allSettled([
			reqCities,
			reqPlaces,
		]);

		return {
			cities: { data: cities.value?.data },
			places: { data: places.value?.data },
		};
	},
);

const reducerFactory = (/* builder */) => {};

export default {
	reducerFactory,
	action: loadTownPlaceSuggestions,
};
