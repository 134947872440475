import { ROUTE_PATHS } from '@src/containers/Session/constants';
import { getCity } from '@src/containers/Session/selectors/getCity';
import getFavourites from '@src/containers/Session/selectors/getFavourites';
import { ANALYTICS_GOAL, useReachGoal } from '@src/libs/hooks/useReachGoal';
import React from 'react';
import { useSelector } from 'react-redux';
import Header from '..';

const HeaderCHome = () => {
	const city = useSelector(getCity);
	const favs = useSelector(getFavourites);
	const favsQtty = favs?.spaces?.length || 0;
	const favLink = ROUTE_PATHS.SPACES_FAVOUR.replace('[town]', city?.domain);

	const { reachGoal } = useReachGoal();
	const handleClickFav = React.useCallback(() => {
		reachGoal(ANALYTICS_GOAL.click_favorites_btn);
	}, [reachGoal]);

	return (
		<Header
			isHomePage
			adaptive="homepage"
			likesQtty={favsQtty}
			onClickFav={handleClickFav}
			favourLink={favsQtty > 0 ? favLink : null}
		/>
	);
};

export default HeaderCHome;
