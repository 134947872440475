import { CITIES_WHITELIST } from '@src/containers/Filters/constants';
import loadTownPlaceSuggestions from '@src/containers/Search/reducers/loadTownPlaceSuggestions';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useDebounce, useUpdateEffect } from 'react-use';

const SUGGESTION_TYPES = {
	CITY: 'city',
	PLACE: 'place',
};

const mapCities = (serverCities) => {
	return serverCities.map((i) => {
		return {
			value: i.name,
			type: 'location',
			suggestionType: SUGGESTION_TYPES.CITY,
			text: i.country?.name,
			details: i,
			disabled: !CITIES_WHITELIST[i.id],
			id: i.id,
		};
	});
};

const mapPlaces = (serverPlaces) => {
	return serverPlaces.map((i) => {
		return {
			value: i.name,
			text: i.address,
			suggestionType: SUGGESTION_TYPES.PLACE,
			details: i,
			disabled: !CITIES_WHITELIST[i.city.id],
			id: i.id,
		};
	});
};

const mapSuggestions = (result) => {
	const cities = result.cities?.data?.cities || [];
	const places = result.places?.data?.restaurants || [];

	return [...mapPlaces(places), ...mapCities(cities)];
};

const useTownPlaceSearch = ({
	minQueryLength = 1,
	debounceTime = 700,
	initialQuery = '',
} = {}) => {
	const dispatch = useDispatch();
	const activeRequest = React.useRef();
	const [query, changeQuery] = React.useState(initialQuery);
	const [loading, setLoading] = React.useState(true);
	const [suggestions, setSuggestions] = React.useState([]);
	/**
	 * @deprecated
	 */
	const [lastPositiveSuggestions, setLastPositiveSuggestions] =
		React.useState([]);

	useUpdateEffect(() => {
		changeQuery(initialQuery);
	}, [initialQuery]);

	const setQuery = React.useCallback(
		(val) => {
			if (activeRequest.current) {
				activeRequest.current.abort();
				activeRequest.current = null;
			}
			if (val !== query) {
				setSuggestions([]);
				changeQuery(val);
				setLoading(val.length > minQueryLength);
			}
		},
		[minQueryLength, query],
	);

	useDebounce(
		() => {
			if (query.length > minQueryLength) {
				activeRequest.current = dispatch(
					loadTownPlaceSuggestions.action({ q: query }),
				);

				activeRequest.current.then((result) => {
					if (result.payload) {
						const mappedSuggestions = mapSuggestions(
							result.payload,
						).sort((a, b) => a.disabled - b.disabled);

						setSuggestions(mappedSuggestions);

						if (mappedSuggestions.length > 0) {
							setLastPositiveSuggestions(mappedSuggestions);
						}
					}
					setLoading(false);
				});
			}
		},
		debounceTime,
		[query],
	);

	React.useEffect(() => {
		return () => {
			activeRequest.current?.abort();
			setLoading(false);
		};
	}, []);

	return {
		query,
		loading,
		suggestions,
		setQuery,
		SUGGESTION_TYPES,
		lastPositiveSuggestions,
	};
};

export default useTownPlaceSearch;
