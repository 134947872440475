import React from 'react';
import { connectServerSideProps } from '@src/store';
import ssrSession from '@src/containers/Session/reducers/runSSR';
import getSessionCtx from '@src/libs/utils/getSessionCtx';
import LayoutBaseCHome from '@src/components/LayoutBase/connectors/home';
import HomePageCDefault from '@src/components/Homepage/connectors/default';
import homepageSSR from '@src/containers/Homepage/reducers/homepageSSR';
import SeoHeadCHomepage from '@src/components/SeoHead/connectors/homepage';
import fetchTitles from '@src/containers/Session/reducers/fetchTitles';

const Home = () => {
	return (
		<>
			<SeoHeadCHomepage />
			<LayoutBaseCHome>
				<HomePageCDefault />
			</LayoutBaseCHome>
		</>
	);
};

export const getServerSideProps = connectServerSideProps(
	(store) => async (ctx) => {
		const { dispatch } = store;
		await dispatch(ssrSession.action(getSessionCtx(ctx)));
		await dispatch(homepageSSR.action());
		await dispatch(fetchTitles.action({ type: 'homepage' }));
	},
);

export default Home;
