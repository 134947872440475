import React from 'react';
import { useSelector } from 'react-redux';
import getHomepageSuggestions from '@src/containers/Filters/selectors/getHomepageSuggestions';
import getEventsParams from '@src/containers/Homepage/selectors/getEventsParams';

import HomepageEvents from '..';

const HomepageEventsCDefault = (props) => {
	const items = useSelector(getHomepageSuggestions);
	const { title } = useSelector(getEventsParams);

	return (
		<HomepageEvents
			{...props}
			items={items}
			src="/img/null.svg"
			forceVisibility
			title={title}
			adaptive="homepage"
		/>
	);
};

export default HomepageEventsCDefault;
