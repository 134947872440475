import React from 'react';
import useFavourites from './useFavourites';

const useHandleClickLike = () => {
	const { addSpaceToFavour, removeSpaceFromFavour } = useFavourites();

	const handleClickLike = React.useCallback(
		(isLiked, id) => {
			if (!isLiked) {
				addSpaceToFavour({ id });
			} else {
				removeSpaceFromFavour({ id });
			}
		},
		[addSpaceToFavour, removeSpaceFromFavour],
	);

	return handleClickLike;
};

export default useHandleClickLike;
