import { getEvents } from '@src/containers/Filters/selectors/getEvents';
import useSuggestions from '@src/libs/hooks/useSuggestions';
import { useField } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';

import SuggestedSearch from '..';
import { useInputAsSelect } from '../hooks';

const SuggestedSearchCEvents = () => {
	const events = useSelector(getEvents);
	const [field, , { setValue }] = useField('spec');
	const initialQuery =
		events.find((event) => field.value === event.value)?.label || '';

	const {
		query,
		isPopoverOpen,
		handleFocusChange,
		handleSelectSuggestion,
		handleResetQuery,
	} = useSuggestions({ initialQuery, setValue });

	const { textInputProps } = useInputAsSelect({
		isOpen: isPopoverOpen,
		handleFocusChange,
	});

	return (
		<SuggestedSearch
			placeholder={t('Что планируете?')}
			name="spec"
			value={query}
			isPopover={isPopoverOpen}
			suggestions={events}
			onBlur={handleFocusChange}
			onSuggestionClick={handleSelectSuggestion}
			onInputClean={handleResetQuery}
			selectedValue={field.value}
			textInputProps={textInputProps}
		/>
	);
};

export default SuggestedSearchCEvents;
