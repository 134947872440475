import React from 'react';

/**
 * Хук для использования поля ввода в качестве `select`.
 * @access private
 * @param {object} param0 Опции хука, должны содержать свойства `isOpen` и `handleFocusChange`
 * @returns объект со свойствами поля ввода, позволяющие превратить его в `select`
 */
export const useInputAsSelect = ({ isOpen, handleFocusChange }) => {
	const handleClick = React.useCallback(
		(_, input) => {
			handleFocusChange(!isOpen);
			if (isOpen) input.blur();
		},
		[isOpen, handleFocusChange],
	);

	const textInputProps = React.useMemo(
		() => ({
			readonly: true,
			onClick: handleClick,
		}),
		[handleClick],
	);

	return { textInputProps, handleClick };
};
