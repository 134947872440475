import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useUpdateEffect } from 'react-use';
import css from './style.module.scss';

const Tag = (props) => {
	const { value, defaultChecked, onClick, label, className, ...rest } = props;

	const [isChecked, setChecked] = useState(defaultChecked);

	useUpdateEffect(() => {
		setChecked(defaultChecked);
	}, [defaultChecked]);

	const handleClick = useCallback(
		(e) => {
			if (onClick) onClick.call(null, e);
			setChecked(true);
		},
		[onClick],
	);

	return (
		<button
			role="menuitemradio"
			type="button"
			aria-checked={isChecked}
			value={value}
			className={cn(css.tag, className)}
			onClick={handleClick}
			{...rest}
		>
			{label || value}
		</button>
	);
};

Tag.propTypes = {
	/**
	 * Передаваемое значение. Аналог атрибута `value` на элементе `<input type="radio">`
	 */
	value: PropTypes.string,
	/**
	 * Условие для выделения. Аналогично атрибуту `defaultChecked` на элементе `<input type="radio">`
	 */
	defaultChecked: PropTypes.bool,
	/**
	 * Обработчик клика.
	 */
	onClick: PropTypes.func,
	/**
	 * Текст радио-кнопки.
	 */
	label: PropTypes.string,
	className: PropTypes.string,
};

Tag.defaultProps = {
	value: undefined,
	defaultChecked: false,
	onClick: undefined,
	label: undefined,
	className: undefined,
};

export default Tag;
