import React from 'react';
import PropTypes from 'prop-types';
import Img from '@src/components/Img';

import css from './style.module.scss';
import NLink from '../NLink';

const EventCard = (props) => {
	const { isShallow, title, href, forceVisibility, src, src2x } = props;
	return (
		<div className={css.block}>
			<div className={css.ratio} />
			{src && (
				<Img
					src={src}
					forceVisibility={forceVisibility}
					className={css.img}
					width={144}
					height={165}
					srcSet={src2x && `${src2x} 2x`}
				/>
			)}
			{title && <div className={css.title}>{title}</div>}
			{href && (
				<NLink href={href} shallow={isShallow}>
					<a className={css.link} />
				</NLink>
			)}
		</div>
	);
};

EventCard.propTypes = {
	title: PropTypes.string,
	isShallow: PropTypes.bool,
	href: PropTypes.string,
	src: PropTypes.string,
	src2x: PropTypes.string,
	forceVisibility: PropTypes.bool,
};

EventCard.defaultProps = {
	title: undefined,
	isShallow: false,
	href: '',
	src: undefined,
	src2x: undefined,
	forceVisibility: false,
};

export default EventCard;
